#circles2 {
  $duration: 60s;
  $darkColor: #000;
  $brightColor: lemonchiffon; // <3
  $birdColor: cornflowerblue; // original pink: #f78bce

  body {
    margin: 0;
    padding: 0;
    background: $darkColor;
  }

  svg.circleGroup {
    width: 100%;
    height: 100%;
    background: transparent;
    position: absolute;

    animation: grow $duration ease-in-out alternate infinite;
  }

  .circ0 {
    fill: $brightColor;
  }

  .circ1 {
    fill: $darkColor;
  }

  @keyframes grow {
    0% {
      transform: scale(1.0);
    }
    100% {
      transform: scale(5);
    }
  }

  $center-spot: calc(50% - 8px);

  #twirc {
    width: 16px;
    height: 16px;
    background: transparent;
    position: absolute;
    right: $center-spot;
    bottom: $center-spot;

    path {
      fill: $birdColor;
    }
    animation: grow $duration ease-in-out alternate infinite;
  }

}
